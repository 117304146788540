@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.tippy-tooltip.discord-theme {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: default;
  user-select: none;
  padding: 4px 10px;
  background-color: black;
  color: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

@media (max-width: 768px) {
  .tippy-tooltip.discord-theme {
    font-size: 0.7rem;
  }
}

.tippy-tooltip.discord-theme .arrow-regular {
  filter: drop-shadow(0 2px 16px 0 rgba(0, 0, 0, 0.5));
}

.tippy-popper[x-placement^="top"] .tippy-tooltip.discord-theme .arrow-regular {
  border-top-color: black;
}

.tippy-popper[x-placement^="bottom"] .tippy-tooltip.discord-theme .arrow-regular {
  border-bottom-color: black;
}

.tippy-popper[x-placement^="left"] .tippy-tooltip.discord-theme .arrow-regular {
  border-left-color: black;
}

.tippy-popper[x-placement^="right"] .tippy-tooltip.discord-theme .arrow-regular {
  border-right-color: black;
}

body, html {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: rgb(36, 36, 36);
  background-color: rgb(244 244 245);
  scroll-behavior: smooth;
}

*::selection {
  background: rgb(82 82 82 / 0.1);
  color: rgb(83, 83, 83);
}

.white svg path {
  stroke: #ffffff;
}

*::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: rgb(150, 150, 150);
  border-radius: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent
}

*::-webkit-scrollbar-button {
  background: transparent;
}

.checkbox {
  display: none;
}

.checkbox:checked + label svg {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}